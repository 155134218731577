<template>
	<div id="Classify">
		<div class="tabbox flc">
			<div class="tabbox-l">
				<div class="tabbox-l-i" @mouseover="mouseoverOne(index)" @mouseleave="mouseleaveOne()"
					v-for="(item,index) in classifyList" :key="index"
					:style="{backgroundColor: index === classifyOneIndex ? '#F2F2F2' : ''}">
					<router-link class="text-none" :to="{name:'shop',query:{cc:item.id}}">
						{{item.title}}
					</router-link>
					<div class="tabbox-posi" v-if="isClassify && index === classifyOneIndex">
						<div class="tabbox-posi-one">
							<!-- <div class="tabbox-posi-one-title">
								{{item.title}}
							</div> -->
							<div class="tabbox-posi-two" v-for="(item2,index2) in item.children" :key="index2">
								<router-link class="tabbox-posi-two-title text-none"
									:to="{name:'shop',query:{cc:item.id+'-'+item2.id}}">
									{{item2.title}}
								</router-link>
								<!-- <img class="tabbox-posi-two-pic" src="../../assets/img/arrows.png"> -->
								<div class="tabbox-posi-three flw" v-if="item2.children.length> 0">
									<router-link class="tabbox-posi-three-i text-none"
										:to="{name:'shop',query:{cc:item.id+'-'+item2.id+'-'+item3.v}}"
										v-for="(item3,index3) in JSON.parse(item2.children[0].json)" :key="index3">
										{{item3.key}}
									</router-link>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div class="tabbox-c">
				<el-carousel class="tabbox-c-b" height="456px">
					<el-carousel-item class="tabbox-c-b-i" v-for="(item,index) in bannerList" :key="index">
						<el-image class="tabbox-c-b-i-i" :src="item.url" :fit="'cover'">
						</el-image>
					</el-carousel-item>
				</el-carousel>
			</div>

			<div class="tabbox-r">
				<div v-if='imges'>
					<img :src="imges" style="height: 40px;width: 40px;border-radius: 50%;margin-left: 77px;" v-if="nickname!==null" @click="hearclick">
				</div>
				<div v-else>
					<img src="../../assets/img/person.png" style="height: 40px;width: 40px;border-radius: 50%;margin-left: 77px;" v-if="nickname!==null" @click="hearclick">
				</div>
			
				<div class="tabbox-r-t">
					Hi,{{nickname}}你好
				</div>
				<div class="tabbox-r-h">
					欢迎光临物卖空
				</div>
				<div class="tabbox-r-d flcs" v-if="nickname===null">
					<!-- <div class="tabbox-r-d-l">
						登录
					</div> -->
					<router-link class="tabbox-r-d-l" style="cursor: pointer;" :to="'login'">
						登录
					</router-link>
					<!-- <div class="tabbox-r-d-r">
						注册
					</div> -->
				</div>
				<!-- <div class="tabbox-r-d flcs" v-else>
					<button type="button" @click="quit">退出</button>
				</div> -->
				<div class="tabbox-r-tab">
					<div class="tabbox-r-tab-i" style="cursor: pointer;" v-for="(item,index) in tabList" :key="index" @click="shoppings(index)">
						<img class="tabbox-r-tab-i-t" :src="item.pic">
						<div class="tabbox-r-tab-i-b" >
							{{item.name}}
						</div>
					</div>
				</div>
			</div>

			<kefu v-if="dialogVisible"></kefu>
			
			
		</div>
	</div>
</template>

<script>
	import kefu from "../common/kefu.vue"
	import {
		bannerbannerList,
		goodsgetAllCategory,
		sundryPhone
	} from "../../utils/index.js"

	export default {
		name: "Classify",
		components:{
			kefu
		},
		data() {
			return {
				tabList: [{
					name: "我的订单",
					pic: require("../../assets/img/index1.png")
				}, {
					name: "我的积分",
					pic: require("../../assets/img/index2.png")
				}, {
					name: "优惠券",
					pic: require("../../assets/img/index3.png")
				}, {
					name: "用户中心",
					pic: require("../../assets/img/index4.png")
				}, {
					name: "联系客服",
					pic: require("../../assets/img/index5.png")
				}],
				isClassify: false,
				// 一级分类
				classifyOneList: [
					[],
					[],
					[],
					[],
					[],
					[],
					[],
					[]
				],
				classifyOneIndex: '',
				// 分类列表
				classifyList: [],
				// 轮播列表
				bannerList: [],
				nickname:'',
				imges:'',
				uid:'',
				dialogVisible: false,
				phone:1
			}
		},
		created() {
			this.bannerbannerList()
			this.goodsgetAllCategory()
			this.sundryPhone()
			this.nickname=localStorage.getItem('nickname')
			this.imges=localStorage.getItem("headImg")
		},
		methods: {
			quit(){
				// 清除状态保持
				window.localStorage.clear()
				// 状态保持清除后刷新页面
				window.location.reload()
			},
			hearclick(){
				this.$router.push({
					path:'/userinfo',
					query:{
						type:0
					}
					})
			},
			shoppings(id){
				//console.log("id",id)
				let a=localStorage.getItem("uid")
				if(a!==null){
					if(id===0){
						this.$router.push({
							path:'/userinfo',
							query:{
								type:1
							}
						});
					}else if(id===1||id===3){
						this.$router.push({
							path:'/userinfo',
							query:{
								type:0
							}
						});
					}else if(id===2){
						this.$router.push({
							path:'/userinfo',
							query:{
								type:5
							}
						});
					}else if(id===4){
						/* if(this.dialogVisible==true){
							this.dialogVisible=false
						}else{
							this.dialogVisible=true
						} */
						const routeData = this.$router.resolve({
							name: 'Chat'
						})
						window.open(routeData.href, '_blank')
					}
				}else{
					this.$router.push({
						path:'/login'
					});
				}
				
			},
			// banner列表
			bannerbannerList() {
				bannerbannerList().then(res => {
					// console.log("banner列表", res);
					this.bannerList = res.data
				})
			},
			// 获取所有分类
			goodsgetAllCategory() {
				goodsgetAllCategory().then(res => {
					// console.log("获取所有分类", res);
					this.classifyList = res.data
				})
			},
			mouseoverOne(e) {
				this.classifyOneIndex = e
				this.isClassify = true
			},
			mouseleaveOne() {
				this.classifyOneIndex = ''
				this.isClassify = false
			},
			sundryPhone(){
				sundryPhone().then(res=>{
					//console.log("客服电话",res)
					this.phone=res.data
				})
			},
		}
	}
</script>

<style >
	
</style>

import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

const router = new Router({
	// mode: 'history',
	// mode:'hash',//或者直接不写
	routes: [{
			path: '/',
			name: 'index',
			component: () => import('@/components/index/index'),
		},
		{
			path: '/shop',
			name: 'shop',
			component: () => import('@/components/shop/shop'),
		},
		{
			path: '/shop_detail',
			name: 'shop_detail',
			component: () => import('@/components/shop/shop_detail'),
		},
		{
			path: '/shop_pay',
			name: 'shop_pay',
			component: () => import('@/components/shop/shop_pay'),
		},
		{
			path: '/shop_page',
			name: 'shop_page',
			component: () => import('@/components/shop/shop_page'),
		},
		{
			path: '/userinfo',
			name: 'userinfo',
			component: () => import('@/components/userinfo/userinfo'),
		},
		{
			path: '/about',
			name: 'about',
			component: () => import('@/components/about/about'),
		},
		{
			path: '/about_detail',
			name: 'about_detail',
			component: () => import('@/components/about/about_detail'),
		},
		{
			path: '/SecondHand',
			name: 'SecondHand',
			component: () => import('@/components/index/SecondHand'),
		},
		{
			path: '/sale_apply',
			name: 'sale_apply',
			component: () => import('@/components/userinfo/sale_apply'),
		},
		{
			path: '/comment_submit',
			name: 'comment_submit',
			component: () => import('@/components/userinfo/comment_submit'),
		},
		{
			path: '/car',
			name: 'car',
			component: () => import('@/components/car/car'),
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('@/components/login/login'),
		},
		/* ,
		{
			path: '/address',
			name: 'address',
			component: () => import('@/components/index/address'),
		} */
		{
			path: '/OrderDetails',
			name: 'OrderDetails',
			component: () => import('@/components/userinfo/OrderDetails'),
		},
		{
			path: '/ShowMap',
			name: 'ShowMap',
			component: () => import('@/components/map/ShowMap'),
		},
		{
			path: '/Stores_home',
			name: 'stores_home',
			component: () => import('@/components/about/stores_home'),
		},
		{
			path: '/Chat',
			name: 'Chat',
			component: () => import('@/components/chat/chat'),
		},
		{
			path: '/Recruitment',
			name: 'Recruitment',
			component: () => import('@/components/index/Recruitment'),
		},
		{
			path: '/REscess',
			name: 'REscess',
			component: () => import('@/components/index/REscess'),
		},
		{
			path: '/xiazai',
			name: 'xiazai',
			component: () => import('@/components/index/xiazai'),
		},
		{
			path: '/yonghuxieyi',
			name: 'yonghuxieyi',
			component: () => import('@/components/login/yonghuxieyi'),
		},
		{
			path: '/yinsi',
			name: 'yinsi',
			component: () => import('@/components/login/yinsi'),
		},
		{
			path: '/ruzhu',
			name: 'ruzhu',
			component: () => import('@/components/login/ruzhu'),
		}
	]
})


const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}

/* 
router.beforeEach((to, from, next) => {

	if (to.path === '/') {

		next(); //此页面不需要登录验证
	} else {
		let footballId = sessionStorage.getItem('$footballId')
		if (footballId === null || footballId === '') {
			next('/');
		} else {
			next();
		}
	}
}); */

export default router

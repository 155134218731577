//现在进行第二层封装 新建一个 http.js 文件，这个文件主要是封装接口的请求
var requests = require('./request.js')
var request = requests.default.request

//banner列表
const bannerbannerListUrl = '/front/banner/bannerList';
var bannerbannerList = function(params) {
	return request({
		url: bannerbannerListUrl,
		method: 'POST',
		data: params
	});
}

//获取所有分类
const goodsgetAllCategoryUrl = '/front/goods/getAllCategory';
var goodsgetAllCategory = function(params) {
	return request({
		url: goodsgetAllCategoryUrl,
		method: 'POST',
		data: params
	});
}

//企业列表
const companycompanyListUrl = '/front/company/companyList';
var companycompanyList = function(params) {
	return request({
		url: companycompanyListUrl,
		method: 'POST',
		data: params
	});
}

//店铺详情
const companycompanyInfoUrl = '/front/company/companyInfo';
var companycompanyInfo = function(params) {
	return request({
		url: companycompanyInfoUrl,
		method: 'POST',
		data: params
	});
}

//推荐二手商品
const goodstypeListUrl = '/front/goods/typeList';
var goodstypeList = function(params) {
	return request({
		url: goodstypeListUrl,
		method: 'POST',
		data: params
	});
}

//企业商品列表
const companycommodityListUrl = '/front/company/commodityList';
var companycommodityList = function(params) {
	return request({
		url: companycommodityListUrl,
		method: 'POST',
		data: params
	});
}

//商品列表
const goodslistUrl = '/front/goods/list';
var goodslist = function(params) {
	return request({
		url: goodslistUrl,
		method: 'POST',
		data: params
	});
}

//获取省市
const citygetAllListUrl = '/front/city/getAllList';
var citygetAllList = function(params) {
	return request({
		url: citygetAllListUrl,
		method: 'POST',
		data: params
	});
}

//商品详情
const goodsinfoUrl = '/front/goods/info';
var goodsinfo = function(params) {
	return request({
		url: goodsinfoUrl,
		method: 'POST',
		data: params
	});
}

//商品收藏
const goodscollectionUrl = '/front/goods/collection';
var goodscollection = function(params) {
	return request({
		url: goodscollectionUrl,
		method: 'POST',
		data: params
	});
}

//加入购物车
const orderaddShoppingUrl = '/front/order/addShopping';
var orderaddShopping = function(params) {
	return request({
		url: orderaddShoppingUrl,
		method: 'POST',
		data: params
	});
}

//立即购买
const orderbuyNowUrl = '/front/order/buyNow';
var orderbuyNow = function(params) {
	return request({
		url: orderbuyNowUrl,
		method: 'POST',
		data: params
	});
}

//订单详情
const orderinfoUrl = '/front/order/info';
var orderinfo = function(params) {
	return request({
		url: orderinfoUrl,
		method: 'POST',
		data: params
	});
}

//用户优惠券列表
const consumercouponListUrl = '/front/consumer/couponList';
var consumercouponList = function(params) {
	return request({
		url: consumercouponListUrl,
		method: 'POST',
		data: params
	});
}

//用户地址列表
const consumeraddressListUrl = '/front/consumer/addressList';
var consumeraddressList = function(params) {
	return request({
		url: consumeraddressListUrl,
		method: 'POST',
		data: params
	});
}

//添加地址
const consumeraddAddressUrl = '/front/consumer/addAddress';
var consumeraddAddress = function(params) {
	return request({
		url: consumeraddAddressUrl,
		method: 'POST',
		data: params
	});
}

//修改地址
const consumereditAddressUrl = '/front/consumer/editAddress';
var consumereditAddress = function(params) {
	return request({
		url: consumereditAddressUrl,
		method: 'POST',
		data: params
	});
}

//用户详情
const consumerinfoUrl = '/front/consumer/info';
var consumerinfo = function(params) {
	return request({
		url: consumerinfoUrl,
		method: 'POST',
		data: params
	});
}

//积分比例
const sundrydiscountUrl = '/front/sundry/discount';
var sundrydiscount = function(params) {
	return request({
		url: sundrydiscountUrl,
		method: 'POST',
		data: params
	});
}

//修改订单
const ordereditOrderUrl = '/front/order/editOrder';
var ordereditOrder = function(params) {
	return request({
		url: ordereditOrderUrl,
		method: 'POST',
		data: params
	});
}

//订单列表
const orderlistUrl = '/front/order/list';
var orderlist = function(params) {
	return request({
		url: orderlistUrl,
		method: 'POST',
		data: params
	});
}

//购物车列表
const ordershoppingListUrl = '/front/order/shoppingList';
var ordershoppingList = function(params) {
	return request({
		url: ordershoppingListUrl,
		method: 'POST',
		data: params
	});
}

//删除购物车
const orderdelShoppingUrl = '/front/order/delShopping';
var orderdelShopping = function(params) {
	return request({
		url: orderdelShoppingUrl,
		method: 'POST',
		data: params
	});
}

//修改购物车数量
const ordereditShoppingNumUrl = '/front/order/editShoppingNum';
var ordereditShoppingNum = function(params) {
	return request({
		url: ordereditShoppingNumUrl,
		method: 'POST',
		data: params
	});
}

//购物车结算
const ordershoppingBuyUrl = '/front/order/shoppingBuy';
var ordershoppingBuy = function(params) {
	return request({
		url: ordershoppingBuyUrl,
		method: 'POST',
		data: params
	});
}

//收藏列表
const goodscollectionListUrl = '/front/goods/collectionList';
var goodscollectionList = function(params) {
	return request({
		url: goodscollectionListUrl,
		method: 'POST',
		data: params
	});
}

//浏览记录
const goodshistoryUrl = '/front/goods/history';
var goodshistory = function(params) {
	return request({
		url: goodshistoryUrl,
		method: 'POST',
		data: params
	});
}

///front/order/addComment 添加评论
const orderAddCommentUrl = '/front/order/addComment';
var orderAddComment = function(params) {
	return request({
		url: orderAddCommentUrl,
		method: 'POST',
		data: params
	});
}

// 扫码支付
const notifycodeUrl = '/front/wx/notify/code';
var notifycode = function(params) {
	return request({
		url: notifycodeUrl,
		method: 'POST',
		data: params
	});
}

// 查询订单状态
const orderselectStatusUrl = '/front/order/selectStatus';
var orderselectStatus = function(params) {
	return request({
		url: orderselectStatusUrl,
		method: 'POST',
		data: params
	});
}

///front/consumer/loginPhone 手机号登入
const consumerLoginPhoneUrl = '/front/consumer/loginPhone';
var consumerLoginPhone = function(params) {
	return request({
		url: consumerLoginPhoneUrl,
		method: 'POST',
		data: params
	});
}

///front/sundry/phone 客服电话
const sundryPhoneUrl = '/front/sundry/phone';
var sundryPhone = function(params) {
	return request({
		url: sundryPhoneUrl,
		method: 'POST',
		data: params
	});
}

///front/order/closeOrder 关闭订单
const orderCloseOrderUrl = '/front/order/closeOrder';
var orderCloseOrder = function(params) {
	return request({
		url: orderCloseOrderUrl,
		method: 'POST',
		data: params
	});
}

///front/goods/feedback 反馈商品
const goodsFeedbackUrl = '/front/goods/feedback ';
var goodsFeedback = function(params) {
	return request({
		url: goodsFeedbackUrl,
		method: 'POST',
		data: params
	});
}

///front/sundry/sms 短信
const sundrySmsUrl = '/front/sundry/sms ';
var sundrySms = function(params) {
	return request({
		url: sundrySmsUrl,
		method: 'POST',
		data: params
	});
}

///front/goods/newList 新品首发
const goodsNewListUrl = '/front/goods/newList ';
var goodsNewList = function(params) {
	return request({
		url: goodsNewListUrl,
		method: 'POST',
		data: params
	});
}

///front/consumer/editAid 设置默认地址
const consumerEditAidUrl = '/front/consumer/editAid';
var consumerEditAid = function(params) {
	return request({
		url: consumerEditAidUrl,
		method: 'POST',
		data: params
	});
}

// 举报商品
const goodsreportUrl = '/front/goods/report';
var goodsreport = function(params) {
	return request({
		url: goodsreportUrl,
		method: 'POST',
		data: params
	});
}

///front/company/addAttr申请入住
const companyAAddAttrUrl = '/front/company/addAttr';
var companyAAddAttr = function(params) {
	return request({
		url: companyAAddAttrUrl,
		method: 'POST',
		data: params
	});
}

///front/im/add 添加聊天
const imAddUrl = '/front/im/add';
var imAdd = function(params) {
	return request({
		url: imAddUrl,
		method: 'POST',
		data: params
	});
}

///front/im/infoList 聊天详情
const imInfoListUrl = '/front/im/infoList';
var imInfoList = function(params) {
	return request({
		url: imInfoListUrl,
		method: 'POST',
		data: params
	});
}

///front/im/list 聊天列表
const imListUrl = '/front/im/list';
var imList = function(params) {
	return request({
		url: imListUrl,
		method: 'POST',
		data: params
	});
}

///front/company/companyGoodsList 店铺商品
const companyCompanyGoodsListUrl = '/front/company/companyGoodsList';
var companyCompanyGoodsList = function(params) {
	return request({
		url: companyCompanyGoodsListUrl,
		method: 'POST',
		data: params
	});
}

///front/goods/getCoupons 领取优惠券
const goodsGetCouponsUrl = '/front/goods/getCoupons';
var goodsGetCoupons = function(params) {
	return request({
		url: goodsGetCouponsUrl,
		method: 'POST',
		data: params
	});
}

///front/goods/couponList 店铺优惠券列表
const goodsCouponListUrl = '/front/goods/couponList ';
var goodsCouponList  = function(params) {
	return request({
		url: goodsCouponListUrl,
		method: 'POST',
		data: params
	});
}

///front/im/noticeList 通知列表
const imNoticeListUrl = '/front/im/noticeList';
var imNoticeList = function(params) {
	return request({
		url: imNoticeListUrl,
		method: 'POST',
		data: params
	});
}

///front/sundry/downloadList 下载列表
const sundryDownloadListUrl = '/front/sundry/downloadList';
var sundryDownloadList = function(params) {
	return request({
		url: sundryDownloadListUrl,
		method: 'POST',
		data: params
	});
}

///front/im/sysNoticeList 系统通知
const imSysNoticeListUrl = '/front/im/sysNoticeList ';
var imSysNoticeList = function(params) {
	return request({
		url: imSysNoticeListUrl,
		method: 'POST',
		data: params
	});
}

///front/sundry/agreement 入驻协议
const sundryAgreementUrl = '/front/sundry/agreement';
var sundryAgreement = function(params) {
	return request({
		url: sundryAgreementUrl,
		method: 'POST',
		data: params
	});
}

///front/consumer/feedbackList 反馈列表
const consumerFeedbackListUrl = '/front/consumer/feedbackList';
var consumerFeedbackList = function(params) {
	return request({
		url: consumerFeedbackListUrl,
		method: 'POST',
		data: params
	});
}

///front/consumer/reportList 举报列表
const consumerReportListUrl = '/front/consumer/reportList';
var consumerReportList = function(params) {
	return request({
		url: consumerReportListUrl,
		method: 'POST',
		data: params
	});
}

module.exports = {
	consumerFeedbackList,
	consumerReportList,
	sundryAgreement,
	imNoticeList,
	sundryDownloadList,
	imSysNoticeList,
	goodsGetCoupons,
	goodsCouponList,
	imAdd,
	imInfoList,
	imList,
	companyAAddAttr,
	consumerEditAid,
	sundrySms,
	goodsFeedback,
	orderCloseOrder,
	sundryPhone,
	bannerbannerList,
	goodsgetAllCategory,
	companycompanyList,
	companycompanyInfo,
	goodstypeList,
	companycommodityList,
	goodslist,
	citygetAllList,
	goodsinfo,
	goodscollection,
	orderaddShopping,
	orderbuyNow,
	orderinfo,
	consumercouponList,
	consumeraddressList,
	consumeraddAddress,
	consumereditAddress,
	consumerinfo,
	sundrydiscount,
	ordereditOrder,
	orderlist,
	ordershoppingList,
	orderdelShopping,
	ordereditShoppingNum,
	ordershoppingBuy,
	goodscollectionList,
	goodshistory,
	orderAddComment,
	notifycode,
	orderselectStatus,
	consumerLoginPhone,
	goodsNewList,
	goodsreport,
	companyCompanyGoodsList
}

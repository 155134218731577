<template>
	<div id="app">
		<router-view>
			<Index />
		</router-view>
	</div>
</template>

<!-- <script charset="utf-8" src="http://map.qq.com/api/js?v=2.exp&key=3QZBZ-KV5KD-Q2O4X-P2P54-VBA7V-ZIBCW"></script> -->

<!-- <script src="https://3gimg.qq.com/lightmap/components/geolocation/geolocation.min.js"></script> -->

<script>
	import Index from './components/index/index.vue'

	export default {
		name: 'App',
		components: {
			Index
		}
	}
</script>

<style>
	@import url("./assets/css/common.css");
	#app{
		/* background: url(assets/img/91f3adcb2316ca7a5a62f0302e7b7a6a.gif)  ;
		background-size: 1920px 1080px;
		background-repeat: no-repeat;
		background-attachment:fixed; */
	}
</style>

<template>
	<div id="Index">
		<Navigation />

		<Classify></Classify>

		<!-- 企业介绍 - 商品展示 -->
		<Introduce></Introduce>

		<!-- 推荐商品 -->
		<RecommendShop></RecommendShop>

		<SideNavigation></SideNavigation>

		<div style="width: 120px;height: 200px;">
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Navigation from "../common/navigation.vue"
	import Classify from "./classify.vue"
	import Introduce from "./introduce.vue"
	import RecommendShop from "./recommend_shop.vue"
	import SideNavigation from "../common/side_navigation.vue"
	import Footer from "../common/footer.vue"

	export default {
		name: 'Index',
		components: {
			Navigation,
			Introduce,
			RecommendShop,
			Classify,
			SideNavigation,
			Footer
		},
		data() {
			return {
				uid:''
			}
		},
		created() {
			//localStorage.setItem("uid", 5)
			this.$forceUpdate()
		}
	}
</script>

<style lang="less">
	@import url("index.less");
</style>

<template>
	<div id="Introduce">
		<div class="introduceBox flcs">
			<div class="introduceBox-l">
				<router-link class="text-none" :to="'about'">
					<div class="introduceBox-l-t flc">
						<div class="introduceBox-l-t-l">
							品牌好店
						</div>
						<img class="introduceBox-l-t-r" src="../../assets/img/index6.png">
					</div>
				</router-link>
				<div class="introduceBox-l-b flc">
					<router-link :to="{name:'stores_home',query: { AD: item.shopId}}"	class="introduceBox-l-b-i text-none"
						:style="{marginRight:index == 3 ? '0' : '55px'}" v-for="(item,index) in companyList"
						:key="index">
						<el-image class="introduceBox-l-b-i-t" :src="item.cover" :fit="'cover'">
						</el-image>
						<div class="introduceBox-l-b-i-b line-one">
							{{item.title}}
						</div>
					</router-link>
				</div>
			</div>

			<div class="introduceBox-l">
				<router-link class="text-none" :to="'SecondHand'">
				<div class="introduceBox-l-t flc">
					<div class="introduceBox-l-t-l">
						二手可利用材料
					</div>
					<img class="introduceBox-l-t-r" src="../../assets/img/index6.png">
				</div>
				</router-link>
				<div class="introduceBox-l-b flc">
					<router-link :to="{name:'shop_detail',query:{id:item.id}}" class="introduceBox-l-b-i cursor" :style="{marginRight:index == 3 ? '0' : '55px'}"
						v-for="(item,index) in goodsList" :key="index">
						<el-image class="introduceBox-l-b-i-t" :src="item.cover" :fit="'cover'">
						</el-image>
						<div class="introduceBox-l-b-i-b" style="font-weight: bold;color: #D80000;">
							¥{{item.price}}
						</div>
					</router-link>
				</div>
			</div>
		</div>

		<div class="shopshow flc">
			<div class="shopshow-l">
				<img class="shopshow-l-i" src="../../assets/img/index7.png">
				<div class="shopshow-l-t">
					新品 首发
				</div>
			</div>
				<vueSeamlessScroll :data="companyGoodList" :class-option="classOption" style="overflow: hidden;">
					<ul class="shopshow-r flc">
					  <li v-for="(item,index) in companyGoodList" :key="index">
						<router-link :to="{ name: 'shop_detail', query: { id: item.id}}" class="introduceBox-l-b-i text-none">
					   <div class="shopshow-r-i fldc" >
						<!-- <el-image class="shopshow-r-i-pic" src="../../assets/img/1.jpg" :fit="'cover'">
						</el-image> -->
						<img class="shopshow-r-i-pic" :src="item.cover"  >
						<div class="shopshow-r-i-D line-three">
							{{item.title||''}}
						</div>
						<div class="shopshow-r-i-y">
							¥{{item.price}}
						</div>
					   </div>
						</router-link >
					  </li>
					</ul>
				</vueSeamlessScroll>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		companycompanyList,
		goodstypeList,
		companycommodityList,
		goodsNewList
	} from "../../utils/index.js"
	import vueSeamlessScroll from "vue-seamless-scroll"

	export default {
		name: "Introduce",
		components:{
			vueSeamlessScroll
		},
		data() {
			return {
				// 企业介绍
				companyList: [],
				// 企业商品列表
				companyGoodList: [],
				// 二手商品
				goodsList: [],
				list:[
					{
						cover:'../../assets/img/1.jpg',
						title:'XXXXXXX'
					},
					{
						cover:'../../assets/img/1.jpg',
						title:'XXXXXXX'
					},
					
					
				],
				cityId:'',//城市ID
				provinceId:''//省id
			}
		},
		created() {
			let str = localStorage.getItem("$local") || ''
			if (str != '') {
				let arr = str.split(',')
				
				if(arr.length==2){
					for (var i = 0; i < arr.length; i++) {
						if (i == 0) {
							//console.log("省",arr[i])
							this.provinceId=arr[i]
							//this.fromData.province = arr[i]
						} else {
							//console.log("市",arr[i])
							this.cityId=arr[i]
							//this.fromData.city = arr[i]
						}
					}
				}else{
					this.provinceId=arr[0]
				}
				
			}
			
			
			// if (str != '') {
			// 	let arr = str.split(',')
			// 	for (var i = 0; i < arr.length; i++) {
			// 		if (i == 0) {
			// 			//console.log("省",arr[i])
			// 			this.provinceId=arr[i]
			// 			//this.fromData.province = arr[i]
			// 		} else {
			// 			//console.log("市",arr[i])
			// 			this.cityId=arr[i]
			// 			//this.fromData.city = arr[i]
			// 		}
			// 	}
			// }
			this.companycompanyList()
			this.goodstypeList()
			this.goodsNewList()
			
			
		},
		computed: {
		  classOption () {
		    return {
		      step: 1, // 数值越大速度滚动越快
		      limitMoveNum: 6, // 开启无缝滚动的数据量 设置(数值<=页面展示数据条数不滚)(超过页面展示条数滚动)
		      openWatch: true, // 开启数据实时监控刷新dom
		      singleHeight: 10,
			  direction:2,
		      waitTime: 0 // 单行停顿时间(singleHeight,waitTime)
		    }
		  }
		},
		methods: {
			// 企业列表
			companycompanyList() {
				companycompanyList({
					cityId:this.cityId,
					provinceId:this.provinceId,
					page:1,
					size:6
				}).then(res => {
					 //console.log("企业列表", res);
					this.companyList = res.data.records.slice(0, 3)
				})
			},
			// 推荐二手商品
			goodstypeList() {
				// 1推荐2二手
				goodstypeList({
					type: 2,
					cityId:this.cityId,
					provinceId:this.provinceId
				}).then(res => {
					 //console.log("推荐二手商品", res);
					this.goodsList = res.data.slice(0, 3)
				})
			},
			// 企业商品列表
			// companycommodityList() {
			// 	companycommodityList({
			// 		page: 1,
			// 		size: 10
			// 	}).then(res => {
			// 		 console.log("企业商品列表", res);
			// 		this.companyGoodList = res.data.records
			// 	})
			// }
			//新品首发
			goodsNewList(){
				goodsNewList({
					cityId:this.cityId,
					provinceId:this.provinceId
				}).then(res=>{
					//console.log("新品首发", res);
					this.companyGoodList = res.data
				})
			}
		}
	}
</script>

<style>
</style>

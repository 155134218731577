<template>
	<div class="">
		<el-dialog
		style="min-width: 1200px;"
		class="dialog1 el-dialog__body el-dialog__header"
		
		  :visible.sync="dialogVisible"
		  width="55%"
		  :show-close="false"
		  >
		  <div style="width: 100%;display: flex;padding-top: -30px;">
		  	<div style="width: 30%;background-color: #FFFFFF;height: 800px;">
		  		<div id="de-hear">
		  			<img src="../../assets/img/1.jpg" class="de-img" >
					<div id="textA">
						奥利奥
					</div>
		  		</div>
				<div id="" style=" height: 660px;border-right: 1px solid #cccccc;"><!-- overflow-y: scroll; -->
					<div id="de-L-list1">
						<img src="../../assets/img/1.jpg" class="de-img1" >
						<div id="textB">
							奥利奥
						</div>
					</div>
					<div id="de-L-list" v-for="item in 6">
						<img src="../../assets/img/1.jpg" class="de-img1" >
						<div id="textB">
							奥利奥
						</div>
					</div>
				</div>
		  	</div>
			<div style="width: 70%;height: 800px;">
				<div class="de-R-hear">
					<img src="../../assets/img/1.jpg" style="width: 60px;height: 60px;border-radius: 50%;margin: 10px 10px 10px 20px;">
					<div class="de-textss">
						美的官方客服_小美
					</div>
				</div>
				<div class="de-content">
					<div class="content-L">
						<img src="../../assets/img/1.jpg" style="width: 40px;height: 40px;border-radius: 50%;margin:10px 10px 10px 20px ;" >
						<div class="c-text">
							--欢迎光临美的官方旗舰店--
							美的智能小家电，WIFI互联，365天只换不修，智慧生活可以更美的，更多详情可咨询客服！
						</div>
					</div>
					<div class="content-R">
						<div class="c-text1">
							--欢迎光临美的官方旗舰店--
							美的智能小家电，WIFI互联，365天只换不修，智慧生活可以更美的，更多详情可咨询客服！
						</div>
						<img src="../../assets/img/1.jpg" style="width: 40px;height: 40px;border-radius: 50%;margin:10px 20px 10px 10px ;" >
					</div>
				</div>
				<div class="de-input">
					<textarea rows="" cols="" placeholder="请输入" style="width: 95%;height:85px;margin-left: 15px;border: none;text-decoration: none;outline:none;font-size: 14px;overflow-y: hidden;"></textarea>
					<div class="fasong">
						发送
					</div>
				</div>
			</div>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
	name: "kefu",
	data() {
		return{
			dialogVisible: true,
		}
	},
	methods:{
		
		}
	}
</script>

<style lang="less" scope>
	.dialog1 .el-dialog__body {
	   padding: 0 !important ;  
	    color: #606266;
	    font-size: 14px;
	    word-break: break-all;
	}
	.dialog1 .el-dialog__header {
	    /* padding: 20px 20px 10px; */
		padding: 0 !important ;
	}
	#textA{
		font-size: 20px;
		font-family: PingFang SC;
		font-weight: bold;
		color: #FFFFFF;
	}
	#textB{
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #333333;
	}
	.de-content{
		width: 100%;
		height: 550px;
		.content-L{
			display: flex;
			margin-top: 30px;
			.c-text{
				background: #F8F8F8;
				padding: 10px;
				max-width: 50%;
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				line-height: 24px;
				border-radius: 6px;
			}
		}
		.content-R{
			display: flex;
			margin-top: 30px;
			.c-text1{
				margin-left: 40%;
				background: #C4E4FF;
				padding: 10px;
				max-width: 50%;
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				line-height: 24px;
				border-radius: 6px;
			}
		}
	}
	.de-input{
		border-top: 1px solid #cccccc;;
		width: 100%;
		height: 79px;
		.fasong{
			width: 15%;
			height: 40px;
			line-height: 40px;
			text-align: center;
			background: #FFFFFF;
			border: 1px solid #D80000;
			border-radius: 20px;
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #D80000;
			float: right;
		}
	}
	.de-R-hear{
		display: flex;
		align-items: center;
		width: 100%;
		height: 79px;
		background:#F8F8F8 ;
		border-bottom: 1px solid #cccccc;
		.de-textss{
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #333333;
		}
	}
	#de-hear{
		height: 140px;
		background: #D80000;
		display: flex;
		align-items: center;
	}
	#de-L-list{
		display: flex;
		align-items: center;
		background: #F8F8F8;
		height: 60px;
	}
	#de-L-list1{
		display: flex;
		align-items: center;
		background: #FFE9E9;
		height: 60px;
	}
	.de-img{
		width: 80px;
		height: 80px;
		border-radius: 50%;
		background-color: #F8F8F8;
		margin:30px 20px 30px 30px;
	}
	.de-img1{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: #F8F8F8;
		margin:30px 20px 30px 30px;
	}
</style>

<template>
	<div id="Navigation">
		<div class="navigation-bgc">
			<div class="navigation flcs">
				<div class="navigation-l flc cursor" @mouseover="mouseoverClick" @mouseleave="mouseleaveClick"
					:style="{backgroundColor: isPop ? '#ffffff' : '#f8f8f8', border : isPop ? '1px solid #EEEEEE' : '1px solid #f8f8f8' }">
					<img class="navigation-l-l" src="../../assets/img/location.png">
					<div class="navigation-l-r">
						{{address ||"抚顺"}}
					</div>

					<div class="navigation-l-p" v-if="isPop">
						<div class="navigation-l-p-t" style="border-bottom: 1px solid #D5D5D5;">
							<div class="navigation-l-p-t-i cursor" v-for="(item,index) in cityList" :key="index"
								:style="{backgroundColor: chooseIndex === index ? '#D80000' : '#ffffff' , color: chooseIndex === index ? '#ffffff' : '#333333'}"
								@click="chooseClick(index,item)">
								{{item.name}}
							</div>
						</div>
						<div class="navigation-l-p-t">
							<div class="navigation-l-p-t-i cursor"
								:style="{backgroundColor: chooseTwoIndex === index2 ? '#D80000' : '#ffffff' , color: chooseTwoIndex === index2 ? '#ffffff' : '#333333'}"
								v-for="(item2,index2) in cityList[chooseIndex].child" :key="index2"
								@click="chooseTwoClick(index2,item2)">
								{{item2.name}}
							</div>
						</div>
					</div>
				</div>
				<div class="navigation-r flc">
					
					<div class="navigation-r-a">
						你好,欢迎光临物卖空
					</div>
					<router-link class="navigation-r-b text-none" :to="'login'"  v-if="uid==null">
						登录
					</router-link>
					<div v-else style="display: flex;">
						<div class="navigation-r-b text-none" >
							{{nickname}}
						</div>
						<div class="navigation-r-d cursor"   @click="quit">
							退出
						</div>
					</div>
					<div class="navigation-r-d cursor" @click="clickDian(5)"  >
						商家入驻
					</div>
					<div class="navigation-r-d cursor"  @click="clickD(4)"  >
						<a href="https://www.wumaikong.com/short_selling">商家登录</a>
					</div>
					<!-- <router-link class="navigation-r-c text-none" :to="'login'">
						注册
					</router-link> -->
					<div class="navigation-r-d cursor"  @click="clickD(1)" >
						我的订单
					</div>
					<!-- <div class="navigation-r-d">
						我的订单
					</div -->
					<div class="navigation-r-d cursor"  @click="clickD(2)" >
						用户中心
					</div>
					<div class="text-none cursor"   @click="clickD(6)" >
						<div class="navigation-r-f">
							相关下载
						</div>
					</div>
					
				</div>
			</div>
		</div>

		<div class="navigation-box">
			<div class="navigation-box-h flc">
				<img class="navigation-box-h-l" src="../../assets/img/logo.png" @click="Login">
				
				<!-- 宝贝，店铺  搜索设置 -->
				<div class="flc" style=" border: 2px solid #D80000;border-radius: 6px;margin-left: 52px;">
					<div class="navigati-click-box" @click="shoptypeclick()">
						<div class="navigati-click">
							<div>{{shopnames}}</div>
							<img class="click-imgs" src="../../assets/img/right1.png">
						</div>
						<div class="navigati-click1" v-if="shoptypesshow==true" @click="Stypeclick()">
							<div style="margin-right: 40px;">{{shopnames1}}</div>
						</div>
					</div>

					<input class="navigation-box-h-i"  v-if="isShop"
					 v-model="condition" :placeholder="shopplaceholder" @keyup.enter="searchClick"/>
					<input class="navigation-box-h-i" v-else
					v-model="condition" :placeholder="shopplaceholder" @keyup.enter="submit"/>
					<div class="navigation-box-h-c flcc" v-if="isShop" 
																		@click="searchClick"
																		@keyup.enter="searchClick">
						<img class="navigation-box-h-c-l" src="../../assets/img/search.png">
						<div class="navigation-box-h-c-r text-none">
							搜索
						</div>
					</div>
					<div class="navigation-box-h-c flcc" v-else  
																@click="submit" 
																@keyup.enter="submit">
						<img class="navigation-box-h-c-l" src="../../assets/img/search.png">
						<div class="navigation-box-h-c-r text-none">
							搜索
						</div>
					</div>
					
				</div>
				<div class="navigation-box-h-r flcc cursor" @click="clickD(3)">
					<img class="navigation-box-h-r-l" src="../../assets/img/car.png">
					<div class="navigation-box-h-r-r">
						购物车
					</div>
				</div>
				
				<div class="navigation-box-text flc">
					<div class="" style="display: flex;"  v-for="(item,index) in Llist" :key="index" >
						<div class="navigation-box-text-i" @click="Dinaji(index)"
						style="cursor: pointer;" >
							{{item}}
						</div>
						<!-- <div class="navigation-box-text-i">
							手套
						</div>
						<div class="navigation-box-text-i">
							零件
						</div> -->
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import {
		citygetAllList
	} from "../../utils/index.js"

	export default {
		name: "Navigation",
		props: {
			conditions: {
				type: String,
				default: ""
			},
			isShop: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				isPop: false,
				chooseIndex: 0,
				chooseTwoIndex: "",
				condition: "",
				cityList: [],
				address: "",
				uid:'',
				nickname:'',
				Llist:[],
				aDDID:0,
				shoptypes:0,//选择店铺 宝贝类型
				shoptypesshow:false,
				shopnames:'商品',//选择店铺 宝贝名字
				shopnames1:'店铺',//未选择店铺 宝贝名字
				shopplaceholder:'搜索前请先选择想要购买物品的区域'//input的placeholde显示
			}
		},
		created() {
			if (this.conditions) {
				this.condition = this.conditions
			}
			
			// if(localStorage.getItem("$local")==""){
			// 	localStorage.setItem("$local", [18,250])
			// }
			
			if (localStorage.getItem("$citygetAll")) { //获取所有城市信息
				this.cityList = JSON.parse(localStorage.getItem("$citygetAll"))
				  this.cityList.forEach((e)=>{
					  //console.log("城市",e.child)
						if(e.id==this.aDDID){
							this.address=e.name
							//console.log("aaa",e.name)
						}
				})
			} else {
				this.citygetAllList()
			}
			
			if(localStorage.getItem("$local")){ //显示城市信息
				 let add=localStorage.getItem("$local")
				 if(add.split(",").length>=2){
					  this.aDDID=add.split(",")[1]
					 this.cityList.forEach((e)=>{
					 	 // console.log("城市",e.child)
					 		if(e.id==add.split(",")[0]){
					 			e.child.forEach((es)=>{
									if(es.id==this.aDDID)
									this.address=es.name
								})
					 			//console.log("aaa",e.name)
					 		}
					 })
				 }else{
					 this.aDDID=add.split(",")[0]
					 this.cityList.forEach((e)=>{
					 	  //console.log("城市",e.child)
					 		if(e.id==this.aDDID){
					 			this.address=e.name
					 			//console.log("aaa",e.name)
					 		}
					 })
				 }
			}
			let Lli=localStorage.Llist
			if(Lli){
				this.Llist=JSON.parse(Lli)
			}
			
			// let add=localStorage.getItem("$local")
			
			this.uid=localStorage.getItem("uid")
			this.nickname=localStorage.getItem("nickname")
		},
		updated() {
			
			if(localStorage.getItem("shoptypes")){
				this.shoptypes=localStorage.getItem("shoptypes")
			}
		},
		watch: {
			conditions(val) {
				this.condition = val
				
			},
			shoptypes(val) {
				this.shoptypes = val
				
			}
		},
		methods: {
			shoptypeclick(){
				this.shoptypesshow==false?this.shoptypesshow=true:this.shoptypesshow=false
				//this.shoptypesshow!=this.shoptypesshow
				//console.log(this.shoptypesshow)
			},
			Stypeclick(){
				this.shopnames=='商品'?this.shopnames="店铺":this.shopnames="商品"
				//this.shopnames=='宝贝'?localStorage.setItem("shopnames",'店铺'):localStorage.setItem("shopnames",'宝贝')
				this.shopnames1=='店铺'?this.shopnames1="商品":this.shopnames1="店铺"
				this.shoptypes==0?this.shoptypes=1:this.shoptypes=0
				//this.shoptypes==1?localStorage.setItem("shoptypes",0):localStorage.setItem("shoptypes",1)
				this.shopplaceholder=='搜索前请先选择想要购买物品的区域'?this.shopplaceholder='搜索前请先选择想了解的公司、店铺的区域':this.shopplaceholder='搜索前请先选择想要购买物品的区域'
				
				//window.location.reload()
			},
			Login(){
				this.$router.push({
					path:"/"
				})
			},
			Dinaji(index){
				this.condition=''
				this.condition=this.Llist[index]
			},
			submit() {
				//判断是否有重复
				var index = this.Llist.findIndex((ele) => {
					return ele == this.condition;
			    });
			    //如果有的话就删除重复
				if (index != -1) {
					this.Llist.splice(index, 1);
			    }
				if(!this.condition==''){
					//向数组第一位添加
					this.Llist.unshift(this.condition);
				}
			    //如果数组长度大于4 就删除最后一项
				if (this.Llist.length > 6) {
					this.Llist.splice(7, 1);
			    }
			    // 本地存储历史记录数组
			    localStorage.Llist = JSON.stringify(this.Llist);
				//this.condition=''
				if(this.shoptypes==0){
					this.$router.push({
						path:'shop',
						query:{condition:this.condition,shoptypes:this.shoptypes}
					})
				}else{
					this.$router.push({
						path:'/about',
						query:{condition:this.condition,shoptypes:this.shoptypes}
					})
				}	
			},
			clickDs(){
				this.Llist.unshift(this.condition)
				localStorage.setItem("Llist",this.Llist)
				this.$router.push({
					path:'shop',
					query:{condition:this.condition,shoptypes:this.shoptypes}
				})
			},
			quit(){
				this.$router.push({
					path:'/',
				})
				// 清除状态保持
				window.localStorage.clear()
				// 状态保持清除后刷新页面
				window.location.reload()
				
			},
			clickDian(index){
				if(index==5){
					// this.$router.push({
					// 	path:'/Recruitment'
					// })
					const routeData = this.$router.resolve({
						name: 'Recruitment'
					})
					window.open(routeData.href, '_blank')
				}
			},
			clickD(index){
				let uid=localStorage.getItem('uid')
				if(uid!==null){
					if(index==1){
						this.$router.push({
							path:'/userinfo',
							query:{type:1}
						})
					}else if(index==2){
						this.$router.push({
							path:'/userinfo',
							query:{type:0}
						})
					}else if(index==3){
						this.$router.push({
							path:'/car'
						})
					}else if(index==4){
						// this.$router.push({
						// 	path:'/userinfo',
						// 	query:{type:0}
						// })
					}else if(index==6){
						this.$router.push({
							path:'/xiazai'
						})
					}
					}else{
					this.$router.push({
						path:'/login',
					})
				}
				
			},
			// 获取省市
			citygetAllList() {
				citygetAllList().then(res => {
					// console.log("获取省市", res);
					this.cityList = JSON.parse(res.data)
					localStorage.setItem("$citygetAll", res.data)
					//this.address = this.cityList[0].name
					this.address = "抚顺"
					localStorage.setItem("$local", [18,250])
				})
			},
			chooseClick(index, item) {
				localStorage.setItem("$local", "")
				this.chooseIndex = index
				this.address = item.name
				this.chooseTwoIndex = ""
				let str = item.id
				console.log(str)
				localStorage.setItem("$local", str)
				this.$emit("localClick")
			},
			chooseTwoClick(index, item) {
				this.chooseTwoIndex = index
				this.address = item.name
				//console.log(item.id)
				let str = localStorage.getItem("$local") + ',' + item.id
				localStorage.setItem("$local", str)
				
				this.$emit("localClick")
			},

			mouseoverClick() {
				this.isPop = true
			},
			mouseleaveClick() {
				this.isPop = false
				location.reload() 
			},
			searchClick() {
				//console.log("1111111111111111111111111111")
				
				if(this.shoptypes==0){
					this.$emit("searchClick", {condition:this.condition,shoptypes:this.shoptypes})
				}else{
					this.$router.push({
						path:'/about',
						query:{condition:this.condition,shoptypes:this.shoptypes}
					})
				}
				//判断是否有重复
				var index = this.Llist.findIndex((ele) => {
					return ele == this.condition;
				});
				//如果有的话就删除重复
				if (index != -1) {
					this.Llist.splice(index, 1);
				}
				if(!this.condition==''){
					//向数组第一位添加
					this.Llist.unshift(this.condition);
				}
				//如果数组长度大于4 就删除最后一项
				if (this.Llist.length > 6) {
					this.Llist.splice(7, 1);
				}
				// 本地存储历史记录数组
				localStorage.Llist = JSON.stringify(this.Llist);
			}
		}
	}
</script>

<style lang="less">
	@import url("navigation.less");
</style>

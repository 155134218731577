import Vue from 'vue'
import App from './App.vue'
import router from './router'

router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
});

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'default-passive-events';

Vue.use(ElementUI);

Vue.config.productionTip = false


import axios from "axios"
axios.defaults.withCredentials = true
									
//axios.defaults.baseURL = 'http://192.168.31.213/short_selling/'
// axios.defaults.baseURL = 'https://www.sywxxcx.com/short_selling'
axios.defaults.baseURL = 'https://www.wumaikong.com/short_selling'
//axios.defaults.baseURL = 'api/'


Vue.prototype.axios = axios

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')

<template>
	<div id="SideNavigation">
		<div class="SideNavigation-box fldc">
			<div class="navigation-box-h-r flcc cursor" @click="clickD" >
				<div class="SideNavigation-box-a" >
					购物车
				</div>
			</div>
			<div class="line">

			</div>
			<div class="SideNavigation-box-b" style="cursor: pointer;" @click="dingdan">
				我的订单
			</div>
			<div class="line">

			</div>
			<div class="SideNavigation-box-b" style="cursor: pointer;" @click="dialogFormVisible = true">
				我要举报
			</div>
			<div class="line">

			</div>
			<div class="SideNavigation-box-b" style="cursor: pointer;" @click="kefus">
				联系客服
			</div>
			
			<div class="line">

			</div>
			<div class="SideNavigation-box-c" style="cursor: pointer;" @click="toTop">
				返回顶部
			</div>
			
			<el-dialog title="我要举报" :visible.sync="dialogFormVisible" :modal="false">
			  <el-form :model="form">
          <el-form-item label="举报内容">
            <el-input v-model="form.content" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="form.username" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input v-model="form.phone" autocomplete="off"></el-input>
          </el-form-item>

			  </el-form>
			  <div slot="footer" class="dialog-footer">
			    <el-button @click="dialogFormVisible = false">取 消</el-button>
			    <el-button type="primary" style="background-color: #D80000; border: none;" @click="cancel" >确 定</el-button>
			  </div>
			</el-dialog>
			
			<div class="kefuclass" v-if="shows">
				<img class="imgs" src="../../assets/img/JUXING.png" >
				<div class="text-sasd">
					客服电话
				</div>
				<div class="text-ss">
					{{phone}}
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import {
		sundryPhone,
		goodsFeedback,
		goodsreport,
	} from "../../utils/index.js"
	import kefu from "./kefu.vue"
	
	
	export default {
		name: "SideNavigation",
		components:{
			kefu
		},
		data() {
			return{
				shows:false,
				phone:0,
				showA:false,
				showMap: false,
				//
				dialogFormVisible: false,
				// form: {
				//   name: '',
				//   delivery: false,
				// },
				form: {
					content: '',
					username:'',
					phone:'',
					// delivery: false,
				},
				formLabelWidth: '120px'
			}
		},
		created() {
			this.sundryPhone()
		},
		methods:{
			cancel(){
				//console.log("成功")
				this.goodsFeedback()
			},
			dian1(){
				this.showA=true
				//console.log(this.showA)
			},
			clickD(){
				let uid=localStorage.getItem('uid')
				if(uid!==null){
					this.$router.push({
						path:"/car"
					})
				}else{
					this.$router.push({
						path:"/login"
					})
				}
			},
			kefus(){
				this.shows=!this.shows
			},
			dingdan(){
				let uid=localStorage.getItem('uid')
				if(uid!==null){
					this.$router.push({
						path:"/userinfo",
						query:{
							type:1
						}
					})
				}else{
					this.$router.push({
						path:"/login"
					})
				}
				
			},
			//客服电话
			sundryPhone(){
				sundryPhone().then(res=>{
					//console.log("客服电话",res)
					this.phone=res.data
				})
			},
			goodsFeedback(){
				if(this.form.username==''){
					this.$message({
						message: '请输入姓名',
						type: 'warning'
					});
					
				}else if(this.form.phone==''){
					this.$message({
						message: '请输入手机号',
						type: 'warning'
					});
				}else if (!(/^1[3456789]\d{9}$/.test(this.form.phone))) {
					this.$message({
						message: '请输入正确的手机号',
						type: 'error'
					});
					this.form.phone=''
				} else if(this.form.content==''){
          this.$message({
            message: '内容不能为空',
            type: 'warning'
          });
        }else{
				goodsreport(this.form).then(res => {
					//console.log("反馈",res)
					if (res.code == 200) {
						this.form.username=''
						this.form.phone=''
						this.form.content=''
						this.$message({
							message: '提交成功',
							type: 'success'
						});
						setTimeout(() => {
							this.dialogFormVisible = false
						}, 1000)
					}
				})
				
				}
			},
			toTop() {
				document.documentElement.scrollTop = 0;
			},
		}
	}
</script>

<style lang="less">
	@import url("side_navigation.less");
	.kefuclass{
		position: fixed;
		width: 232px;
		height: 92px;
		//right:307px;
		left:1370px;
		top:650px;
		//background-image: url(../../assets/img/JUXING.png) 232px 92px ;
		.imgs{
			width: 232px;
			height: 92px;
		}
		.text-sasd{
			position:absolute;
			top:20px;
			left:70px;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
		}
		.text-ss{
			position:absolute;
			top:48px;
			left:30px;
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #B41212;
		}
	}
</style>

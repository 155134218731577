<template>
	<div id="RecommendShop">
		<div class="recommend">
			<div class="recommend-title">
				推荐商品
			</div>
			<div class="recommend-box flw ">
				<router-link :to="{name:'shop_detail',query:{id:item.id}}" class="recommend-box-i cursor"
					:style="{marginRight: index % 5 == 4 ? '0px' : '12.5px'}" v-for="(item,index) in goodsList"
					:key="index">
					<el-image class="recommend-box-i-pic" :src="item.cover" :fit="'cover'">
					</el-image>
					<div class="recommend-box-i-t line-two">
						{{item.title}}
					</div>
					<div class="recommend-box-i-price">
						¥{{item.price}}
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		goodstypeList
	} from "../../utils/index.js"

	export default {
		name: "RecommendShop",
		data() {
			return {
				// 商品列表
				goodsList: [],
				cityId:'',
				provinceId:''
			}
		},
		created() {
			let str = localStorage.getItem("$local") || ''
			if (str != '') {
				let arr = str.split(',')
				
				if(arr.length==2){
					for (var i = 0; i < arr.length; i++) {
						if (i == 0) {
							//console.log("省",arr[i])
							this.provinceId=arr[i]
							//this.fromData.province = arr[i]
						} else {
							//console.log("市",arr[i])
							this.cityId=arr[i]
							//this.fromData.city = arr[i]
						}
					}
				}else{
					this.provinceId=arr[0]
				}
				
			}
			this.goodstypeList()
		},
		methods: {
			// 推荐二手商品
			goodstypeList() {
				// 1推荐2二手
				goodstypeList({
					type: 1,
					cityId:this.cityId,
					provinceId:this.provinceId
				}).then(res => {
					 //console.log("推荐二手商品", res);
					this.goodsList = res.data
				})
			},
		}
	}
</script>

<style>
</style>

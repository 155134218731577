import qs from "qs"
import axios from "axios"

export default {
	request: (params) => {
		// rejected
		return new Promise((resolved) => {
			let url = params.url;
			let data = params.data || {};

			if (!data['uid']) {
				if (localStorage.getItem('uid')) {
					data['uid'] = localStorage.getItem('uid');
				}
			}

			axios.post(url, qs.stringify(data))
				.then(res => {
					resolved(res.data)
				})
		})
	}
}

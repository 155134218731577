<template>
	<div id="Footer">
		<div class="footerbox">
			<div style="width:430px;margin:0 auto; padding:20px 0;">
				<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#ffffff;">地  址：辽宁省沈抚示范区金枫街75-1号
 | 客服电话：4001008707 
				</p>
			</div>
			
			
			<div style="width:420px;margin:0 auto; ">
				
				<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21990102000007" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
						
				<img src="../../assets/img/11.png" style="float:left;margin-left:10px;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#ffffff;">辽公网安备 21990102000007号
				</p></a> <a href="https://beian.miit.gov.cn/"><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#ffffff;"> 辽ICP备2021012920号-2  |  </p></a> 
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Footer"
	}
</script>

<style lang="less">
	@import url("footer.less");
</style>
